import styled from "styled-components"
import React from "react"
import {HashLink} from 'react-router-hash-link'

const Footer = () => {

	return (
		<Wrapper>
			<Content>
				<Navigation>
					<NavigationColumn>
						<Company>АТС24</Company>
						<Socials>
							<a href="https://t.me/ats24ru" target="_blank"><img src="/assets/img/svg/telegram.svg"
																				width={36}/></a>
							<a href="https://wa.me/+79057774237" target="_blank"><img src="/assets/img/svg/whatsapp.svg"
																					  width={36}/></a>
							<a><img src="/assets/img/svg/vk.svg" width={36}/></a>
						</Socials>
						<Contacts>
							<div>Москва, 2-й Рощинский проезд, дом 8</div>
							<div>+7 (495) 212-91-55</div>
							<div><a href="mailto:inbox@ats24.ru">inbox@ats24.ru</a></div>
						</Contacts>
					</NavigationColumn>
					<NavigationColumn>
						<NavigationTitle>Маршрутизация</NavigationTitle>
						<HashLink to="/routing#map">Совместное использование</HashLink>
						<HashLink to="/routing#metrics">Автоматическое планирование</HashLink>
						<HashLink to="/routing#geocode">Ручной режим</HashLink>
						<HashLink to="/routing#schedule">Наши преимущества</HashLink>
						<HashLink to="/routing#performance">Перенос точек</HashLink>
						<HashLink to="/routing#group-use">Печать документов</HashLink>
						<HashLink to="/routing#group-use">Выбор ворот</HashLink>
					</NavigationColumn>
					<NavigationColumn>
						<NavigationTitle>Мониторинг</NavigationTitle>
						<HashLink to="/monitoring#terminal">Терминал</HashLink>
						<HashLink to="/monitoring#logs">Интерактивная карта</HashLink>
						<HashLink to="/monitoring#divisions">Поиск и фильтры</HashLink>
						<HashLink to="/monitoring#archive">Оперативный отчёт</HashLink>
						<HashLink to="/monitoring#fuel-sensor">Журнал событий</HashLink>
						<HashLink to="/monitoring#temp-sensor">Поддержка филиалов</HashLink>
						<HashLink to="/monitoring#door-sensor">Внешние датчики</HashLink>
					</NavigationColumn>
					<NavigationColumn>
						<NavigationTitle>Приложение</NavigationTitle>
						<HashLink to="/mobile-app#route">Маршрутный лист</HashLink>
						<HashLink to="/mobile-app#points-info">Информация о точках</HashLink>
						<HashLink to="/mobile-app#invoices">Электронные накладные</HashLink>
						<HashLink to="/mobile-app#check-lists">Дополнительные опции</HashLink>
						<HashLink to="/mobile-app#photo-fixation">Автоматические статусы</HashLink>
						<HashLink to="/mobile-app#barcode-scanner">Календарь</HashLink>
						<HashLink to="/mobile-app#government-fines">Штрафы ГИБДД</HashLink>
						<HashLink to="/mobile-app#job-calendar">Тёмная тема</HashLink>
					</NavigationColumn>
					<NavigationColumn>
						<NavigationTitle>Сервисы</NavigationTitle>
						<a href="/services/reports">Отчёты</a>
						<a href="/services/fines">Мотивация водителей</a>
						<a href="/services/motivation">Штрафы ГИБДД</a>
						<a href="/services/telegram">Телеграм бот</a>
						<NavigationTitle style={{marginTop: '1.5rem'}}>Дополнительно</NavigationTitle>
						<HashLink to="/price">Тарифы</HashLink>
						<HashLink to="/contacts">Контакты</HashLink>
						{/*<a href="/docs/privacy">Политика конфиденциальности</a>*/}
					</NavigationColumn>
				</Navigation>
			</Content>

		</Wrapper>

	)
}

export default Footer

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: 120px;
    border-top: solid 1px #EEEEEE;
    background: #FFFFFF;
`
const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1320px;
    padding: 0 25px;
    font-size: 16px;
    gap: 20px;

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`
const Navigation = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    gap: 50px;
`
const Company = styled.div`
    font-size: 44px;
    line-height: 44px;
    font-weight: 500;
`
const Socials = styled.div`
    display: flex;
    flex: 1;
    gap: 10px;

    a {
        align-self: flex-start;
        filter: grayscale(100%);
    }

    a:hover {
        filter: none;
    }
`
const NavigationColumn = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    gap: 20px;
`
const NavigationTitle = styled.div`
    text-transform: uppercase;
    color: #697485;
`
const Contacts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    opacity: .6
`
